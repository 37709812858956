import React from 'react'

const DashBoard = () => {
  return (
    <div>
      dashbourd
    </div>
  )
}

export default DashBoard
