import React from 'react'

const Discousion = () => {
  return (
    <div>
      discousion
    </div>
  )
}

export default Discousion
