import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FaRegBookmark, FaRegFileAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { setExamPage } from "../../Redux/examsPageSlice";
import { toast } from "react-toastify";
import examTokenSlice, { setExamToken } from "../../Redux/examToken";
interface responseProps {
  data: any;
  startDate: number;
  endDate: number;
}
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  gap: 20px;

  @media (max-width: 768px) {
    text-align: center;
    padding-left: 0;
  }
`;

const Title = styled.h3`
  font-size: 16px;
  font-weight: bold;
  color: #6c757d;
  margin: 0;
`;

const Subtitle = styled.h1`
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  color: #343a40;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const Details = styled.div`
  font-size: 14px;
  color: #495057;
  line-height: 1.5;
`;

const Info = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin: 10px 0;
  justify-contain:between-space;

  span {
    font-weight: bold;
    color: #343a40;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 5px;
  }
`;

const IconText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #007bff;
  font-size: 14px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Instructions = styled.ul`
  list-style: disc inside;
  padding: 0;
  margin: 10px 0;
  font-size: 14px;
  color: #495057;

  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

const CheckboxContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #495057;

  input {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Button = styled.button`
  margin-top: 20px;
  width: 100px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Image = styled.img`
  flex: 1;
  max-width: 400px;
  border-radius: 8px;
  object-fit: cover;

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const onlineExamUrl = `${process.env.REACT_APP_API_URL}/web/exam`;

const ModalExam = () => {

  // const onlineExamUrl = `${process.env.REACT_APP_API_URL}/web/exam`;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [notiOpen, setnotifyOpen] = useState(false);
  const [responseTime, setResponseTime] = useState<responseProps>({
    data: "",
    startDate: 0,
    endDate: 0,
  });

  // const [automaticExamEndNotice, setautomaticExamEndNotice] = useState(false);
  // const [automaticEndExam, setautomaticEndExam] = useState(false);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
    console.log("searchParams",searchParams);
    console.log("token exam token",token)
  // const [page, setPage] = useState(1); // Current page
  // const [questionsPerPage] = useState(10); // Number of questions per page

  const onlineExamData = async () => {
    try {
      const resp = await axios.get(onlineExamUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setResponseTime((prev) => {
        return {
          ...prev,
          data: resp.data?.data,
          startDate: resp.data.data.startDate,
          endDate: resp.data.data.endDate,
        };
      });
      localStorage.setItem("result", resp.data.data.data.id);
      dispatch(
        setExamPage({
          question: resp.data.data.data.questionAns,
          exam: resp.data.data.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if(token){
    dispatch(setExamToken(token))
  }
    onlineExamData();
    

    const interval = setInterval(() => {
      onlineExamData();
    }, 20000);

    return () => clearInterval(interval);
  }, [token]);

  const currentExam = useSelector((state: any) => state.examPage.exam);
  // const questionlist = useSelector((state: any) => state.examPage.exampagelist);
  // console.log("currentExam", currentExam)
  // const [open, setopen] = useState(false);
  // const [submitModel, setsubmitModel] = useState(false);

  // // const ref = useRef(null);
  // const handleClick = (e: any) => {
  //   e.preventDefault();
  //   setopen(true);
  // };
  // const handleClose = () => {
  //   setopen(false);
  // };

  // const currentTime = new Date().getTime();

  // const renderer = ({ hours, minutes, seconds }: any) => {
  //   return (
  //     <span>
  //       {hours} : hrs {minutes} : mins {seconds} : sec
  //     </span>
  //   );
  // };

  // const handleCloseNotify = (
  //   event: React.SyntheticEvent | Event,
  //   reason?: string
  // ) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setnotifyOpen(false);
  // };

  // const endExam = async () => {
  //   const resultId = localStorage.getItem("result");
  //   const token = searchParams.get("token");

  //   if (resultId) {
  //     await axios.patch(
  //       `${process.env.REACT_APP_API_URL!}/web/result/${resultId}`,
  //       {
  //         isCompleted: true,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     setnotifyOpen(true);
  //     setsubmitModel(false);
  //     toast("Your Exam has been submitted");
  //     navigate("/submit");
  //   } else {
  //     toast("No Result Found. Try Refreshing");
  //   }
  // };

  // Paginate questions
  // const indexOfLastQuestion = page * questionsPerPage;
  // const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  // const currentQuestions = questionlist.slice(
  //   indexOfFirstQuestion,
  //   indexOfLastQuestion
  // );
  // const examToken = useSelector((state: any) => state.examToken)

 const navigateToExamPage=()=>{
  //  const token = searchParams.get("token");
   navigate(`/web/${token}/exampage`);
  

 }
 //for start date
  const startDate = currentExam?.exam?.startDate;
  let formattededStartDate =''
  if (startDate) {
    const timestampSecondsStartDate = startDate / 1000;
    const start = new Date(timestampSecondsStartDate * 1000);

    // Extract date parts
    const startDay = start.getDate();
    const startMonth = start.toLocaleString('default', { month: 'long' });
    const startYear = start.getFullYear();

    // Extract time parts
    const startHours = start.getHours().toString().padStart(2, '0'); // Ensures two digits
    const startMinutes = start.getMinutes().toString().padStart(2, '0'); // Ensures two digits

    // Combine date and time
     formattededStartDate = `${startDay} ${startMonth} ${startYear}, ${startHours}:${startMinutes}`;
    console.log(formattededStartDate); // Output: 27 November 2027, 18:15
  } else {
    console.log("Start date not available");
  }

 // for endDate
  let formattededEndDate=""
  const endDate = currentExam?.exam?.endDate;
  if (endDate) {
    const timestampSeconds = endDate / 1000;
    const date = new Date(timestampSeconds * 1000);

    // Extract date parts
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    // Extract time parts
    const hours = date.getHours().toString().padStart(2, '0'); // Ensures two digits
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensures two digits

    // Combine date and time
     formattededEndDate = `${day} ${month} ${year}, ${hours}:${minutes}`;
    console.log(formattededEndDate); // Output: 27 November 2027, 18:15
  } else {
    console.log("End date not available");
  }



  return (
    <Container>
      <Content>
        <Title>MedPrepNepal</Title>
        {/* Ensure currentExam and exam are not null/undefined */}
        <Subtitle>{currentExam?.exam?.name || "Loading..."}</Subtitle>
        <Details>
          <Info>
            Conducted On: <strong>{formattededStartDate}</strong> to{" "}
            <strong>{formattededEndDate}</strong>
          </Info>
          <Info>
            <div>
              Time: <span>{currentExam?.exam?.time} minutes</span>
            </div>
            <div>
              Marks: <span>{currentExam?.totalQuestions}</span>
            </div>
            <div>
              Questions: <span>{currentExam?.exam?.Question}</span>
            </div>
          </Info>

          <Info>Language: English</Info>
        </Details>
        <IconText>
          <FaRegBookmark /> Bookmark Questions Available
        </IconText>
        <IconText>
          <FaRegFileAlt /> Instructions:
        </IconText>
        <Instructions>
          <li>Ensure a stable internet connection.</li>
          <li>Once started, the timer cannot be paused.</li>
          <li>Submit answers before the timer ends.</li>
        </Instructions>
        <CheckboxContainer>
          <input type="checkbox" id="agree" />
          <label htmlFor="agree">I agree to the terms and conditions</label>
        </CheckboxContainer>
        <Button onClick={navigateToExamPage}>Start</Button>
      </Content>
      <Image
        src="https://res.cloudinary.com/dj0z1c8lh/image/upload/v1734004326/medprep/Asset_1_pqqvmh.png "
        alt="Exam illustration"
      />
    </Container>
  );
};

export default ModalExam;
