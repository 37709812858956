import React, { useEffect, useState } from "react";
import GlobalStyle from "../../../components/style/GlobalStyles";
import axios from "axios";
import "./QnInputData.css";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { changeAns } from "../../../Redux/examsPageSlice";
import { useDispatch, useSelector } from "react-redux";
// import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import CustomRadio from "./CustomRadio";

interface QNinputDataProps {
  index: number;
  selectedAnswer: string;
  optionA: string;
  optionB: string;
  optionC: string;
  optionD: string;
  questionId: string;
  textQuestion: string;
  onBookmark: (any: any) => void
}

const QNinputData: React.FC<QNinputDataProps> = ({
  index,
  selectedAnswer,
  optionA,
  optionB,
  optionC,
  optionD,
  questionId,
  textQuestion,
  onBookmark
  
}) => {
  const dispatch = useDispatch();
  // const [searchParams] = useSearchParams();

  // const token = searchParams.get("token");
  const token = useSelector((state: any) => state.examToken)
  const bookmarks = JSON.parse(localStorage.getItem("bookmarks") || "{}");
    const bookmarkIndex=bookmarks[index]===true
 
  const updateAns = async (e: any) => {
    try {
      // Update the selected answer in Redux state
      dispatch(changeAns({ index, selectedAnswer: e.target.value }));

      // Send the selected answer to the server
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/web/${localStorage.getItem(
          "result"
        )}`,
        {
          question: questionId,
          selectedAnswer: e.target.value,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (error) {
      // Handle error and reset selectedAnswer if necessary
      dispatch(changeAns({ index, selectedAnswer: "" }));
      console.log("Error is:", error);
    }
  };
//  const selectedAnswe=useSelector((state:any)=>state.changeAns)
console.log("selectedAnswer",selectedAnswer)
  // const handleBookmark = () => {
  //   const bookmarks = JSON.parse(localStorage.getItem("bookmarks") || "{}");
  //   if (isBookmarked) {
  //     delete bookmarks[index];
  //   } else {
  //     bookmarks[index] = true;
  //   }
  //   localStorage.setItem("bookmarks", JSON.stringify(bookmarks));
  //   setIsBookmarked(!isBookmarked);
  // };



  // Custom styled Radio button
  // const CustomRadio = styled(Radio)({
  //   position: 'relative',
  //   width: 30,
  //   height: 30,
  //   '& .MuiSvgIcon-root': {
  //     display: 'none', // Hide default radio circle
  //   },
  //   '&:before': {
  //     content: 'attr(data-label)', // Use the `data-label` attribute for text
  //     position: 'absolute',
  //     top: '50%',
  //     left: '50%',
  //     transform: 'translate(-50%, -50%)',
  //     fontSize: '1rem',
  //     fontWeight: 'bold',
  //     color: '#000',
  //     zIndex: 1,
  //   },
  //   '&.Mui-checked:before': {
  //     color: '#fff', // Text color when selected
  //   },
  //   '&.Mui-checked': {
  //     backgroundColor: '#1976d2', // Default primary color
  //     borderRadius: '50%',
  //   },
  //   '&:not(.Mui-checked)': {
  //     border: '1px solid #000',
  //     borderRadius: '50%',
  //   },
  //   '&:after': {
  //     content: '""',
  //     position: 'absolute',
  //     top: '50%',
  //     left: '50%',
  //     width: 0,
  //     height: 0,
  //     backgroundColor: 'rgba(25, 118, 210, 0.5)', // Ripple color
  //     borderRadius: '50%',
  //     transform: 'translate(-50%, -50%)',
  //     opacity: 0.25,
  //     transition: 'width 0.6s ease, height 0.6s ease, opacity 0.6s ease',
  //   },
  //   '&:active:after': {
  //     width: 100, // Ripple grows to 100px (40px button + 30px extension x 2)
  //     height: 100, // Ripple grows to 100px (40px button + 30px extension x 2)
  //     opacity: .25,
  //   },
  // });

  return (
    <FormControl>
      <RadioGroup
        sx={{
          "& .MuiSvgIcon-root": {
            borderRadius: 0,
          },
          "& .MuiRadio-root": {
            padding: 0,
          },
        }}
        aria-labelledby="controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={selectedAnswer || ""}
        onChange={updateAns}
      >
        <GlobalStyle />
        <div className="Question-Wrapper" style={{ padding: "10px" }}>
          <div style={{ width: "100%", maxWidth: "950px" }}>
            <div className="Question-Container">
              <div className="Question-Top">
                <p className="Question-Top-Para">Question</p>
                <p className="Question-Top-Para">:&nbsp;&nbsp; {index + 1}</p>
              </div>
            </div>
            <div className="Question">
              <p className="Question-Para">{textQuestion}</p>
            </div>
            <div className="Container">
              {/* Option A */}
              <div className="Options">
                <div className="Option">
                  <div className="OptionA">
                   
                    <FormControlLabel
                      value="A"
                      control={<CustomRadio data-label="A" />}
                      label="" // Hide external label
                    />
                    <div className="Middle">
                      <p className="Middle-Para">{optionA}</p>
                    </div>
                    <div>
                    
                     
                    </div>
                  </div>
                </div>


                {/* Option B */}
                <div className="Option">
                  <div className="OptionA">
                    <FormControlLabel
                      value="B"
                      control={<CustomRadio data-label="B" />}
                      label="" // Hide external label
                    />
                    <div className="Middle">
                      <p className="Middle-Para">{optionB}</p>
                    </div>
                    <div>

                    </div>
                  </div>
                </div>
              </div>

              {/* Option C */}

              <div className="Options">
                <div className="Option">
                  <div className="OptionA">
                    <FormControlLabel
                      value="C"
                      control={<CustomRadio data-label="C" />}
                      label="" // Hide external label
                    />
                    <div className="Middle">
                      <p className="Middle-Para">{optionC}</p>
                    </div>
                    <div>

                    </div>
                  </div>
                </div>


                {/* Option D */}
                <div className="Option">
                  <div className="OptionA">
                    <FormControlLabel
                      value="D"
                      control={<CustomRadio data-label="D" />}
                      label="" // Hide external label
                    />
                    <div className="Middle">
                      <p className="Middle-Para">{optionD}</p>
                    </div>
                    <div>

                    </div>
                  </div>


                </div>
              </div>
            </div>
            <div
              className="BookMark"
           
            >
              <div 
                onClick={()=>onBookmark(index)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: bookmarkIndex ? "#FC3D39" : "#F1C98BE9",
                  padding: "0px",
                  marginTop:"5px",
                  borderRadius: "15px",
                  width: "30px",
                  height: "30px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="40px"
                  height="20px"
                  style={{
                    color: "#FFFFFFFF",
                    cursor: "pointer",
                  }}
                >
                  <path d="M7 2a1 1 0 00-1 1v18.586a1 1 0 001.707.707L12 18.414l4.293 4.293A1 1 0 0018 22V3a1 1 0 00-1-1H7z" />
                </svg>
              </div>
            </div>
            
          </div>
        </div>
      </RadioGroup>
    </FormControl>
  );
};

export default QNinputData;
