import React from "react";
import { useSelector } from "react-redux";
import { selectEmail, selectName } from "../../Redux/userSlice";
import { Outlet, NavLink } from "react-router-dom";
import styled from "styled-components";
import { FaClipboard } from "react-icons/fa";

const SidebarContainer = styled.div`
  display: flex;
  height: 100vh;  /* Ensure full height */
  overflow: hidden;  /* Prevent scrolling of the sidebar */
`;

const Sidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;  /* Full height */
  background: linear-gradient(180deg, #f8f9fa, #e9ecef);
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 10;  /* Make sure the sidebar is above other content */

  @media (max-width: 768px) {
    width: 70px;  /* Shrink sidebar on small screens */
    padding: 10px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    display: none;  /* Hide title on small screens */
  }
`;

const Divider = styled.hr`
  border: 0;
  height: 1px;
  background-color: #dee2e6;
  margin: 10px 0;
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
`;

const MenuItem = styled.li`
  margin-bottom: 15px;
  font-size: 16px;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #2c3e50;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: #e3f2fd;
      color: #007bff;

      span {
        color: #007bff;
      }
    }

    &.active {
      background-color: #cce5ff;
      color: #0056b3;

      span {
        color: #0056b3;
      }
    }

    @media (max-width: 768px) {
      justify-content: center;  /* Center icon on small screens */
    }
  }

  .text {
    @media (max-width: 768px) {
      display: none;  /* Hide text on small screens */
    }
  }
`;

const Icon = styled.span`
  font-size: 18px;
  margin-right: 10px;
  color: #2c3e50;
  transition: color 0.3s;

  @media (max-width: 768px) {
    margin-right: 0;  /* Remove spacing on small screens */
  }
`;

const Content = styled.div`
  flex: 1;
  margin-left: 250px;  /* Offset content from the sidebar */
  padding: 10px;
  overflow-y: auto;

  @media (max-width: 768px) {
    margin-left: 50px;  /* Adjust content margin for small screens */
  }

  @media (max-width: 540px) {
    margin-left: 50px;  /* Ensure margin is consistent on very small screens */
  }
`;

const SidebarLayout = () => {
  const name: any = useSelector(selectName);
  const email: any = useSelector(selectEmail);
  const examToken = useSelector((state: any) => state.examToken);

  return (
    <SidebarContainer>
      <Sidebar>
        <Title>MedPrepNepal</Title>
        <Divider />
        <Menu>
          <MenuItem>
            <NavLink
              to={`/web?token=${examToken}`}
              end
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <Icon>
                <FaClipboard />
              </Icon>
              <span className="text">Model Exam</span>
            </NavLink>
          </MenuItem>
        </Menu>

        <div>
          <strong>{name}</strong>
          <br />
          {email}
        </div>
      </Sidebar>
      <Content>
        <Outlet />
      </Content>
    </SidebarContainer>
  );
};

export default SidebarLayout;
