import { createSlice } from "@reduxjs/toolkit"


const examTokenSlice:any=createSlice({
    name: "token",
    initialState: null,
    reducers: {
        setExamToken: (state, action) => action.payload,
    },
});

export const {  setExamToken } = examTokenSlice.actions;
export default examTokenSlice.reducer