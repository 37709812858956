
import React from "react";
import QNinputData from "./QnInputData";
import { QuestionsAns } from "./QuestionSheetData";

interface NormalQuestionProps {
  item: QuestionsAns;
  index: number;
  globalIndex: number;
  onBookmark: (any: any) => void

}
const NormalQuestion: React.FC<NormalQuestionProps> = ({
  item,
  index,
  globalIndex,
  onBookmark
}): JSX.Element => {
  return (
    <QNinputData
      index={globalIndex}
      questionId={item.id}
      textQuestion={item.question.textQuestion}
      selectedAnswer={item.selectedAnswer}
      optionA={item.question.optionA}
      optionB={item.question.optionB}
      optionC={item.question.optionC}
      optionD={item.question.optionD}
      onBookmark={onBookmark}
    
    />
  );
};
export default NormalQuestion;
