import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Hidden,
  IconButton,
  Popover,
  Stack,
  Typography,
  Pagination,
} from "@mui/material";
import { Paper } from "@mui/material";
import GlobalStyle from "../../../components/style/GlobalStyles";
import MenuIcon from "@mui/icons-material/Menu";
import "./ExamPage.css";
import axios from "axios";
import NormalQuestion from "../Question Sheet/NormalQuestion";
import Profile from "../Profile/Profile";
import moment from "moment";
import Countdown from "react-countdown";
import { useDispatch, useSelector } from "react-redux";
import { setExamPage } from "../../../Redux/examsPageSlice";
import { useNavigate } from "react-router-dom";
// import SuccessNotify from "../../../components/body/SuccessNotify";
import ExamEnded from "../../../components/notice/ExamEnded";
import ExamNotStarted from "../../../components/notice/ExamNotStarted";
import { ToastContainer, toast } from "react-toastify";
// import { Link } from "react-scroll";
// import { adminService } from "../../../http/admin-service";

interface responseProps {
  data: any;
  startDate: number;
  endDate: number;
}

// function Card({ title }: { title: string }) {
//   return (
//     <>
//       <Stack
//         width="500px"
//         height="300px"
//         margin="auto"
//         mt={10}
//         boxShadow={1}
//         justifyContent="center"
//         alignItems="center"
//         borderRadius={2}
//       >
//         <Typography variant="h5">{title}</Typography>
//       </Stack>
//     </>
//   );
// }

const onlineExamUrl = `${process.env.REACT_APP_API_URL}/web/exam`;
const ExamPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [notiOpen, setnotifyOpen] = useState(false);
  // const [token,setToken]=useState('')
  const [responseTime, setResponseTime] = useState<responseProps>({
    data: "",
    startDate: 0,
    endDate: 0,
  });

  const [automaticExamEndNotice, setautomaticExamEndNotice] = useState(false);
  const [automaticEndExam, setautomaticEndExam] = useState(false);

  // const [searchParams] = useSearchParams();
  // const token = searchParams.get("token");
  const token = useSelector((state: any) => state.examToken)
  console.log("Token", token)
  // useEffect(()=>{
  //   // const token=localStorage.getItem("token");
  //   // const token = searchParams.get("token");
  //   console.log("token on exampage",Token)
  //   // console.log("examtoken", examToken)
  //   if(Token){
  //   setToken(Token)
  //   }

  // },[Token])
  console.log("newToken", token)
  const [page, setPage] = useState(1); // Current page
  const [questionsPerPage] = useState(10); // Number of questions per page

  const onlineExamData = async () => {
    try {
      const resp = await axios.get(onlineExamUrl, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setResponseTime((prev) => {
        return {
          ...prev,
          data: resp.data?.data,
          startDate: resp.data.data.startDate,
          endDate: resp.data.data.endDate,
        };
      });
      localStorage.setItem("result", resp.data.data.data.id);
      dispatch(
        setExamPage({
          question: resp.data.data.data.questionAns,
          exam: resp.data.data.data,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onlineExamData();

    const interval = setInterval(() => {
      onlineExamData();
    }, 20000);

    return () => clearInterval(interval);
  }, []);

  const currentExam = useSelector((state: any) => state.examPage.exam);
  const questionlist = useSelector((state: any) => state.examPage.exampagelist);
  console.log("currentExam", currentExam)
  const [open, setopen] = useState(false);
  const [submitModel, setsubmitModel] = useState(false);

  const ref = useRef(null);
  const handleClick = (e: any) => {
    e.preventDefault();
    setopen(true);
  };
  const handleClose = () => {
    setopen(false);
  };

  const currentTime = new Date().getTime();

  const renderer = ({ hours, minutes, seconds }: any) => {
    return (
      <span>
        {hours} : hrs {minutes} : mins {seconds} : sec
      </span>
    );
  };

  const handleCloseNotify = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setnotifyOpen(false);
  };

  const endExam = async () => {
    const resultId = localStorage.getItem("result");
    // const token = searchParams.get("token");

    if (resultId) {
      await axios.patch(
        `${process.env.REACT_APP_API_URL!}/web/result/${resultId}`,
        {
          isCompleted: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setnotifyOpen(true);
      setsubmitModel(false);
      toast("Your Exam has been submitted");
      navigate("/submit");
    } else {
      toast("No Result Found. Try Refreshing");
    }
  };

  // Paginate questions
  const indexOfLastQuestion = page * questionsPerPage;
  const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
  const currentQuestions = questionlist.slice(
    indexOfFirstQuestion,
    indexOfLastQuestion
  );
  // Add refs for smooth scrolling
  const questionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const answeredItems = currentQuestions.filter((item: any) => item.selectedAnswer !== '');
  // Count the number of filtered items
  const answeredItemsCount = answeredItems.length;
  const allQuestionListLength = questionlist.length
  const [storedBookmarks, setStoredBookmarks] = useState<Record<string, boolean>>({});

  // Load bookmarks from localStorage on mount
  useEffect(() => {
    const bookmarks = JSON.parse(localStorage.getItem("bookmarks") || "{}");
    setStoredBookmarks(bookmarks);
  }, []);

  const handleBookmark = (key: string) => {
    const isBookmarked = storedBookmarks[key] || false;
    const updatedBookmarks = { ...storedBookmarks, [key]: !isBookmarked };

    if (!updatedBookmarks[key]) {
      delete updatedBookmarks[key]; // Remove the key if un-bookmarked
    }

    localStorage.setItem("bookmarks", JSON.stringify(updatedBookmarks));
    setStoredBookmarks(updatedBookmarks);
  };
  console.log("setBookmark", storedBookmarks)
  const numbersOfBookMarks = Object.keys(storedBookmarks).length;
  const questionListdd = [
    { id: 1, bookmarked: false, selectedAnswer:"asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false,  },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
    { id: 1, bookmarked: false, selectedAnswer: "asdjh" },
  
  
  ];

  return (
    <>
      {responseTime.startDate === 0 || responseTime.endDate === 0 ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={responseTime.endDate === 0 && responseTime.startDate === 0}
        >
          <Typography>Checking system. Please wait</Typography>{" "}
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : responseTime.startDate > currentTime ? (
        <ExamNotStarted />
      ) : responseTime.endDate < currentTime ? (
        <ExamEnded />
      ) : currentExam.isCompleted ? (
        <ExamEnded />
      ) : (
        <>
          <div>
            <GlobalStyle />
            <div className="TopBar">
              <p className="Left-TopBar-Para">
                Time Left |{" "}
                {responseTime.data && (
                  <Countdown
                    date={Date.now() + (responseTime.endDate - currentTime)}
                    renderer={renderer}
                  />
                )}
              </p>
              <p className="Right-TopBar-Para">{currentExam.exam.name}</p>
              <div className="menu-icon">
                <IconButton
                  sx={{ padding: "0" }}
                  onClick={handleClick}
                  ref={ref!}
                >
                  <MenuIcon style={{ color: "white" }} />
                </IconButton>
              </div>
              <Popover
                open={open}
                anchorEl={ref.current}
                onClose={handleClose}
                container={ref.current}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Profile />
              </Popover>
            </div>
            <div className="Left-Container">
              <Grid container spacing={2}>
                {/* Right-sided grid */}
                <Grid
                  item
                  xs={12}
                  md={3}
                  order={{ xs: 1, md: 2 }} // Show on top for small screens, order 2 for larger screens
                  className="RightGridSticky"
                >
                  <div className="RefWrapper">
                    <div className="RefMain">
                      <div
                        className="position"
                      >
                        <p className="RefPara">Reference</p>

                        <Grid
                          container
                          spacing={0}
                          sx={{
                            display: "flex",
                            flexWrap: "nowrap", // Prevent wrapping
                            alignItems: "center", // Ensure items align vertically
                          }}
                        >
                          {/* Answered */}
                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Paper
                              elevation={0}
                              sx={{
                                width: "16px",
                                height: "16px",
                                background: "#01B39A",
                                borderRadius: "8px",
                              }}
                            ></Paper>
                            <span style={{ marginLeft: "8px", fontSize: "15px", color: " #6B6B6B" }}>{answeredItemsCount}</span>
                            <p className="Answered" style={{ marginLeft: "4px" }}>Answered</p>
                          </Grid>

                          {/* Bookmarks */}
                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Paper
                              elevation={0}
                              sx={{
                                width: "16px",
                                height: "16px",
                                background: "#FC3D39",
                                borderRadius: "8px",
                              }}
                            ></Paper>
                            <span style={{ marginLeft: "8px", fontSize: "15px", color: " #6B6B6B" }}>{numbersOfBookMarks}</span>
                            <p className="Answered" style={{ marginLeft: "4px" }}>Bookmark</p>
                          </Grid>

                          {/* Unanswered */}
                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Paper
                              elevation={0}
                              sx={{
                                width: "16px",
                                height: "16px",
                                background: "#D9D9D9",
                                borderRadius: "8px",
                              }}
                            ></Paper>
                            <span style={{ marginLeft: "8px", fontSize: "15px", color: " #6B6B6B" }}>
                              {allQuestionListLength - answeredItemsCount}
                            </span>
                            <p className="Answered" style={{ marginLeft: "4px" }}>Unanswered</p>
                          </Grid>
                        </Grid>
                      </div>

                      <div
                        className="scroll"

                      >
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            width: "100%",
                            flexWrap: { xs: "nowrap", md: "wrap" },
                            overflowX: { xs: "auto", md: "hidden" },
                            overflowY: { xs: "hidden", md: "auto" },
                            whiteSpace: { xs: "nowrap", md: "normal" },
                          }}
                        >
                          {questionListdd.map((select: any, index: number) => {
                            const isBookmarked = storedBookmarks[index];
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                key={index}
                                sx={{
                                  padding: "0px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: 'center',
                                }}
                              >
                                <Paper
                                  sx={{
                                    width: { xs: "30px", sm: "50px" },
                                    height: { xs: "30px", sm: "50px" },
                                    color: select.selectedAnswer || isBookmarked ? "#FFFFFF" : "#192120",
                                    background: (isBookmarked && select.selectedAnswer) ? "#9E7978FF" : isBookmarked ? "#EE918F" : select.selectedAnswer ? "#01B39A": "#FFFFFF",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    questionRefs.current[index]?.scrollIntoView({
                                      behavior: "smooth",
                                      block: "start",
                                    });
                                  }}
                                >
                                  {index + 1}
                                </Paper>
                              </Grid>
                            );
                          })}
                        </Grid>

                      </div>
                      <div className="Button-Wrapper">
                        <button className="Submit" onClick={() => setsubmitModel(true)}>
                          Submit
                        </button>
                      </div>
                    </div>

                  </div>
                </Grid>

                {/* Left-sided grid */}
                <Grid
                  item
                  xs={12}
                  md={9}
                  order={{ xs: 2, md: 1 }} // Show below for small screens, order 1 for larger screens
                >
                  <div className="Left-Container-width">
                    <div className="Left-Container-Main">
                      <div className="Left-Container-Header">
                        <div className="Left-Container-Mark">
                          <p>Mark</p>
                          <p>:&nbsp; 100</p>
                        </div>
                        <div className="Left-Container-Question">
                          <p>Questions</p>
                          <p>:&nbsp; 30</p>
                        </div>
                      </div>
                      <div className="Left-Container-Timer">
                        <div className="Left-Container-StartTime">
                          <p>Start Time </p>
                          <p>:&nbsp; {moment(responseTime.startDate).format("LTS")}</p>
                        </div>
                        <div className="Left-Container-EndTime">
                          <p>End Time</p>
                          <p>:&nbsp; {moment(responseTime.endDate).format("LTS")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="Left-Container-Questions">
                      {currentQuestions.map((abc: any, index: number) => (
                        <div
                          className="ExamPageCard"
                          key={index}
                          ref={(el) =>
                            (questionRefs.current[indexOfFirstQuestion + index] = el)
                          } // Assign the ref
                        >
                          <NormalQuestion
                            onBookmark={handleBookmark}
                            item={abc}
                            index={index}
                            globalIndex={indexOfFirstQuestion + index}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </Grid>
              </Grid>;

            </div>
            <Dialog open={submitModel}>
              <DialogContent>
                <Typography variant="h6">Submit Exam ?</Typography>
                <Typography variant="subtitle2">
                  Once submitted, you cannot give exam again
                </Typography>
                <Box my={2}>
                  <Button
                    variant="outlined"
                    sx={{
                      mr: "5px",
                    }}
                    onClick={() => setsubmitModel(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={async () => {
                      await endExam();

                      // navigate("/exams")
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </DialogContent>
            </Dialog>
          </div>
        </>
      )
      }

      {/* {automaticEndExam && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={responseTime.endDate === 0 && responseTime.startDate === 0}
        >
          <Typography>Submitting your exam</Typography>{" "}
          <CircularProgress color="inherit" />
        </Backdrop>
      )} */}
      {/* {automaticEndExam && (
        <Dialog open=>
          <DialogContent>
            Your exam time has ended.
            <Button onClick={endExam}>Submit Exam</Button>
          </DialogContent>
        </Dialog>
      )} */}
    </>
  );
};
export default ExamPage;


