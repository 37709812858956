import styled from "styled-components";
import Radio from "@mui/material/Radio";

// Custom styled Radio button moved outside the component
const CustomRadio = styled(Radio)({
    position: "relative",
    width: 30,
    height: 30,
    "& .MuiSvgIcon-root": {
        display: "none", // Hide default radio circle
    },
    "&:before": {
        content: 'attr(data-label)', // Use the `data-label` attribute for text
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        fontSize: "1rem",
        fontWeight: "bold",
        color: "#000",
        zIndex: 1,
    },
    "&.Mui-checked:before": {
        color: "#fff", // Text color when selected
    },
    "&.Mui-checked": {
        backgroundColor: "#1976d2", // Default primary color
        borderRadius: "50%",
    },
    "&:not(.Mui-checked)": {
        border: "1px solid #000",
        borderRadius: "50%",
    },
    "&:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        width: 0,
        height: 0,
        backgroundColor: "rgba(25, 118, 210, 0.5)", // Ripple color
        borderRadius: "50%",
        transform: "translate(-50%, -50%)",
        opacity: 0.25,
        transition: "width 0.6s ease, height 0.6s ease, opacity 0.6s ease",
    },
    "&:active:after": {
        width: 100, // Ripple grows to 100px
        height: 100, // Ripple grows to 100px
        opacity: 0.25,
    },
});

export default CustomRadio;
